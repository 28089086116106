import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useUiContext } from "src/UIProvider";
import { chosenCourseModel } from "src/entities/chosenCourse/ChosenCourseModel";
import { contentEditorModel } from "src/entities/contentEditor/ContentEditorModel";
import { courseModel } from "src/entities/course/CourseModel";
import { quizModel } from "src/entities/quiz/QuizModel";
import { updatingSentenceModel } from "src/entities/updatingSentence/UpdatingSentenceModel";
import { userModel } from "src/entities/user/UserModel";
import { useOutsideClick } from "src/utils/utils";

const BACK_ROUTES = [
    '/privacy-policy',
    '/terms-of-use',
    '/help-center',
    '/quiz',
    '/bots',
    '/bot-info',
    '/metaminder-info',
 ];

export const useHeader = () => {
    const { locale } = useUiContext();
    const containerRef = useRef<HTMLDivElement>(null)
    const [opened, setOpened] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const showBackButton = BACK_ROUTES.includes(location.pathname);

    useOutsideClick(containerRef, () => {
        setOpened(false);
    })

    const onGoBack = () => {
        navigate(-1);
    }

    const onGoToStart = () => {
        chosenCourseModel.clear();
        // navigate('/course-creation');
        navigate('/');
    }

    const onGoToCourses = () => {
        setOpened(false);
        navigate('/');
    }

    const onGoToMMInfo = () => {
        setOpened(false);
        navigate('/metaminder-info');
    }

    const onLogout = () => {
        setOpened(false);
        localStorage.clear();
        userModel.clear();
        updatingSentenceModel.clear();
        courseModel.clear();
        chosenCourseModel.clear();
        contentEditorModel.clear();
        quizModel.clear();
        navigate('/');
    }

    const onGoToLogin = () => {
        setOpened(false);
        navigate(`/sign-in`, { state: { prevLocation: location } });
    }

    const onGoToHelpCenter = () => {
        setOpened(false);
        navigate('/help-center');
    }

    const onGoToTermsOfUse = () => {
        setOpened(false);
        navigate(`/terms-of-use?lang=${locale || 'en'}`);
    }

    const onGoToPrivacyPolicy = () => {
        setOpened(false);
        navigate(`/privacy-policy?lang=${locale || 'en'}`);
    }

    const onClickMenu = () => {
        setOpened(prev => !prev);
    }

    return {
        opened, containerRef, showBackButton, location, onLogout, onGoToLogin, onGoToCourses, onGoToMMInfo,
        onGoBack, onClickMenu, onGoToTermsOfUse, onGoToStart, onGoToHelpCenter, onGoToPrivacyPolicy
    };

}