import { RefObject, useEffect } from "react";

export const sleep = (duration: number) => new Promise((resolve: Function) => setTimeout(() => resolve(), duration));

export const useOutsideClick = (ref: RefObject<HTMLElement>, callback: (e: any) => void,): void => {
    const handleClick = (e: any) => {
        if ((ref.current && !ref.current.contains(e.target)) && !window.getSelection()?.toString().length) {
            callback(e);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    });
};

export const convertHoursToMinutes = (hours: number) => {
    return Math.round(hours * 60);
}

export const removeTextBeforeAndAfterObjectInString = (text: string) => {
    const firstIndex = text.indexOf('{');
    const lastIndex = text.lastIndexOf('}');
    const result = text.substring(firstIndex, lastIndex + 1);
    const jsonCourse = result.replace(/(.\n)+(\n)/g, '');
    return jsonCourse;
}

export const startsWithString = (string: string, prefix: string) => {
    return string.slice(0, prefix.length) === prefix;
}

export const declOfWord = (num: number, word: Array<string>): string => {
    const cases = [2, 0, 1, 1, 1, 2];
    if (Array.isArray(word)) {
        return `${num} ${word[(num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]] || word[2]}`;
    }
    return '';
};