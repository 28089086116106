import React, { FC } from "react";

interface IProps {
    width?: number;
    height?: number;
}

export const AppLogoIcon: FC<IProps> = ({ width, height }) => (
    <svg width="188" height="58" viewBox="0 0 188 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M83.6367 24.2048V14.1376H87.5679C88.3237 14.1376 88.9675 14.2835 89.4995 14.5751C90.0314 14.8635 90.4369 15.2649 90.7158 15.7794C90.998 16.2907 91.1391 16.8805 91.1391 17.5491C91.1391 18.2176 90.9964 18.8074 90.711 19.3187C90.4255 19.8299 90.012 20.228 89.4703 20.5132C88.9319 20.7983 88.2799 20.9408 87.5144 20.9408H85.0088V19.2351H87.1738C87.5793 19.2351 87.9134 19.1646 88.1761 19.0237C88.4421 18.8795 88.6399 18.6813 88.7697 18.4289C88.9027 18.1733 88.9692 17.88 88.9692 17.5491C88.9692 17.2148 88.9027 16.9231 88.7697 16.6741C88.6399 16.4217 88.4421 16.2268 88.1761 16.0891C87.9101 15.9482 87.5728 15.8778 87.1641 15.8778H85.7434V24.2048H83.6367Z" fill="white" />
        <path d="M103.034 19.1712C103.034 20.269 102.828 21.203 102.416 21.9731C102.008 22.7432 101.45 23.3314 100.743 23.7378C100.039 24.1409 99.2473 24.3424 98.3683 24.3424C97.4828 24.3424 96.6882 24.1392 95.9843 23.7329C95.2804 23.3265 94.7242 22.7383 94.3155 21.9682C93.9068 21.1981 93.7024 20.2657 93.7024 19.1712C93.7024 18.0734 93.9068 17.1394 94.3155 16.3693C94.7242 15.5992 95.2804 15.0126 95.9843 14.6095C96.6882 14.2032 97.4828 14 98.3683 14C99.2473 14 100.039 14.2032 100.743 14.6095C101.45 15.0126 102.008 15.5992 102.416 16.3693C102.828 17.1394 103.034 18.0734 103.034 19.1712ZM100.898 19.1712C100.898 18.4601 100.793 17.8604 100.582 17.3721C100.374 16.8838 100.081 16.5135 99.7014 16.2612C99.3219 16.0088 98.8776 15.8827 98.3683 15.8827C97.8591 15.8827 97.4147 16.0088 97.0352 16.2612C96.6557 16.5135 96.3606 16.8838 96.1497 17.3721C95.9421 17.8604 95.8383 18.4601 95.8383 19.1712C95.8383 19.8823 95.9421 20.482 96.1497 20.9703C96.3606 21.4586 96.6557 21.8289 97.0352 22.0812C97.4147 22.3336 97.8591 22.4597 98.3683 22.4597C98.8776 22.4597 99.3219 22.3336 99.7014 22.0812C100.081 21.8289 100.374 21.4586 100.582 20.9703C100.793 20.482 100.898 19.8823 100.898 19.1712Z" fill="white" />
        <path d="M107.833 24.2048L104.982 14.1376H107.283L108.933 21.1325H109.015L110.835 14.1376H112.806L114.62 21.1473H114.708L116.357 14.1376H118.659L115.808 24.2048H113.754L111.857 17.6228H111.779L109.886 24.2048H107.833Z" fill="white" />
        <path d="M121.156 24.2048V14.1376H127.87V15.8925H123.263V18.2913H127.525V20.0462H123.263V22.4499H127.89V24.2048H121.156Z" fill="white" />
        <path d="M130.915 24.2048V14.1376H134.847C135.599 14.1376 136.241 14.2736 136.773 14.5456C137.309 14.8143 137.716 15.1961 137.995 15.691C138.277 16.1825 138.418 16.7609 138.418 17.4262C138.418 18.0947 138.275 18.6698 137.99 19.1515C137.704 19.63 137.291 19.997 136.749 20.2526C136.211 20.5082 135.559 20.636 134.793 20.636H132.161V18.9254H134.453C134.855 18.9254 135.189 18.8697 135.455 18.7583C135.721 18.6469 135.919 18.4797 136.048 18.2569C136.181 18.0341 136.248 17.7571 136.248 17.4262C136.248 17.0919 136.181 16.8101 136.048 16.5807C135.919 16.3513 135.719 16.1776 135.45 16.0596C135.184 15.9384 134.848 15.8778 134.443 15.8778H133.022V24.2048H130.915ZM136.297 19.6234L138.773 24.2048H136.447L134.024 19.6234H136.297Z" fill="white" />
        <path d="M141.277 24.2048V14.1376H147.991V15.8925H143.383V18.2913H147.646V20.0462H143.383V22.4499H148.01V24.2048H141.277Z" fill="white" />
        <path d="M154.568 24.2048H151.036V14.1376H154.597C155.6 14.1376 156.462 14.3392 157.186 14.7423C157.909 15.1421 158.465 15.7172 158.855 16.4676C159.247 17.2181 159.443 18.116 159.443 19.1614C159.443 20.21 159.247 21.1112 158.855 21.8649C158.465 22.6187 157.906 23.1971 157.176 23.6001C156.45 24.0032 155.58 24.2048 154.568 24.2048ZM153.143 22.3811H154.481C155.103 22.3811 155.627 22.2697 156.052 22.0468C156.48 21.8207 156.801 21.4717 157.016 20.9998C157.233 20.5246 157.341 19.9118 157.341 19.1614C157.341 18.4175 157.233 17.8096 157.016 17.3377C156.801 16.8658 156.482 16.5184 156.057 16.2956C155.632 16.0727 155.108 15.9613 154.486 15.9613H153.143V22.3811Z" fill="white" />
        <path d="M166.915 24.2048V14.1376H170.905C171.638 14.1376 172.249 14.2474 172.739 14.467C173.229 14.6865 173.597 14.9913 173.843 15.3813C174.09 15.768 174.213 16.2137 174.213 16.7183C174.213 17.1116 174.135 17.4573 173.98 17.7555C173.824 18.0504 173.61 18.2929 173.337 18.483C173.068 18.6698 172.76 18.8025 172.413 18.8812V18.9795C172.792 18.9959 173.148 19.104 173.478 19.3039C173.813 19.5038 174.083 19.784 174.291 20.1445C174.499 20.5017 174.602 20.9277 174.602 21.4225C174.602 21.9567 174.471 22.4335 174.208 22.853C173.949 23.2692 173.564 23.5985 173.055 23.841C172.546 24.0835 171.918 24.2048 171.172 24.2048H166.915ZM169.022 22.4646H170.739C171.326 22.4646 171.755 22.3516 172.024 22.1255C172.293 21.8961 172.428 21.5913 172.428 21.2112C172.428 20.9326 172.361 20.6868 172.228 20.4738C172.095 20.2608 171.905 20.0937 171.659 19.9724C171.416 19.8512 171.125 19.7906 170.788 19.7906H169.022V22.4646ZM169.022 18.3503H170.584C170.872 18.3503 171.129 18.2995 171.352 18.1979C171.579 18.093 171.758 17.9456 171.887 17.7555C172.02 17.5654 172.087 17.3377 172.087 17.0722C172.087 16.7085 171.959 16.4152 171.703 16.1924C171.45 15.9695 171.09 15.8581 170.623 15.8581H169.022V18.3503Z" fill="white" />
        <path d="M176.759 14.1376H179.119L181.391 18.4732H181.489L183.761 14.1376H186.12L182.486 20.6459V24.2048H180.394V20.6459L176.759 14.1376Z" fill="white" />
        <path d="M83.6367 34.6846H86.2413L88.9921 41.0017H89.1092L91.8601 34.6846H94.4646V44.0867H92.4161V37.9671H92.3332L89.7481 44.0408H88.3532L85.7682 37.9441H85.6852V44.0867H83.6367V34.6846Z" fill="#4A7DFF" />
        <path d="M97.5781 44.0867V34.6846H104.309V36.3235H99.69V38.5639H103.963V40.2028H99.69V42.4478H104.328V44.0867H97.5781Z" fill="#4A7DFF" />
        <path d="M106.981 36.3235V34.6846H115.185V36.3235H112.127V44.0867H110.039V36.3235H106.981Z" fill="#4A7DFF" />
        <path d="M118.411 44.0867H116.147L119.596 34.6846H122.317L125.761 44.0867H123.498L120.996 36.8331H120.918L118.411 44.0867ZM118.269 40.3911H123.615V41.9428H118.269V40.3911Z" fill="#4A7DFF" />
        <path d="M128.337 34.6846H130.941L133.692 41.0017H133.809L136.56 34.6846H139.164V44.0867H137.116V37.9671H137.033L134.448 44.0408H133.053L130.468 37.9441H130.385V44.0867H128.337V34.6846Z" fill="#4A7DFF" />
        <path d="M144.39 34.6846V44.0867H142.278V34.6846H144.39Z" fill="#4A7DFF" />
        <path d="M155.856 34.6846V44.0867H154.032L149.686 38.1691H149.613V44.0867H147.501V34.6846H149.354L153.666 40.5977H153.754V34.6846H155.856Z" fill="#4A7DFF" />
        <path d="M162.515 44.0867H158.974V34.6846H162.545C163.549 34.6846 164.414 34.8728 165.139 35.2493C165.865 35.6226 166.422 36.1598 166.812 36.8607C167.206 37.5615 167.403 38.4001 167.403 39.3765C167.403 40.3559 167.206 41.1975 166.812 41.9015C166.422 42.6054 165.861 43.1456 165.13 43.5221C164.401 43.8985 163.53 44.0867 162.515 44.0867ZM161.086 42.3835H162.428C163.052 42.3835 163.577 42.2795 164.003 42.0713C164.432 41.8602 164.754 41.5342 164.969 41.0935C165.187 40.6497 165.295 40.0774 165.295 39.3765C165.295 38.6817 165.187 38.114 164.969 37.6732C164.754 37.2325 164.434 36.9081 164.008 36.7C163.582 36.4919 163.057 36.3878 162.432 36.3878H161.086V42.3835Z" fill="#4A7DFF" />
        <path d="M170.34 44.0867V34.6846H177.071V36.3235H172.452V38.5639H176.725V40.2028H172.452V42.4478H177.091V44.0867H170.34Z" fill="#4A7DFF" />
        <path d="M180.124 44.0867V34.6846H184.065C184.819 34.6846 185.463 34.8116 185.996 35.0656C186.533 35.3166 186.941 35.6731 187.22 36.1353C187.503 36.5944 187.645 37.1346 187.645 37.7559C187.645 38.3802 187.502 38.9174 187.216 39.3673C186.929 39.8141 186.515 40.1569 185.972 40.3957C185.432 40.6344 184.778 40.7537 184.011 40.7537H181.372V39.1561H183.67C184.073 39.1561 184.408 39.1041 184.674 39C184.941 38.896 185.139 38.7399 185.269 38.5317C185.403 38.3236 185.469 38.065 185.469 37.7559C185.469 37.4437 185.403 37.1805 185.269 36.9663C185.139 36.752 184.939 36.5898 184.67 36.4796C184.403 36.3664 184.066 36.3098 183.66 36.3098H182.236V44.0867H180.124ZM185.518 39.808L188.001 44.0867H185.669L183.24 39.808H185.518Z" fill="#4A7DFF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.8889 8.00103H17.768C16.7885 8.00103 15.7927 8.12533 14.808 8.36914C11.3372 9.21272 8.35965 11.451 6.414 14.5067C4.90066 16.8835 4.01129 19.7545 4 22.8478V49.2223C4 49.6519 4.32436 50 4.72462 50H8.83099C9.23125 50 9.55561 49.6519 9.55561 49.2223V22.9071C9.55561 17.9673 13.2865 13.9628 17.8889 13.9628C22.4912 13.9628 26.2223 17.9673 26.2223 22.9071L26.2789 25.8235V41.5294C26.2789 43.0888 27.4971 44.3529 29 44.3529C30.5029 44.3529 31.7211 43.0888 31.7211 41.5294V25.8235L31.7777 22.9071C31.7777 17.9673 35.5088 13.9628 40.1111 13.9628C44.7135 13.9628 48.4444 17.9673 48.4444 22.9071V49.2223C48.4444 49.6519 48.7687 50 49.169 50H53.2754C53.5424 50 53.7756 49.8451 53.9013 49.6144C53.9641 49.4992 54 49.3653 54 49.2223V22.8478C53.9887 19.7545 53.0993 16.8835 51.586 14.5067C49.6403 11.451 46.6628 9.21272 43.192 8.36914C42.2073 8.12533 41.2115 8.00103 40.232 8.00103H40.1111L39.2657 8H18.7343L17.8889 8.00103ZM37.3333 29.5294C37.3333 27.97 38.5515 26.7059 40.0544 26.7059C41.5573 26.7059 42.7755 27.97 42.7755 29.5294V49.2941C42.7755 49.684 42.4709 50 42.0952 50H38.0136C37.6379 50 37.3333 49.684 37.3333 49.2941V29.5294ZM42.7755 22.1176C42.7755 23.6771 41.5573 24.9412 40.0544 24.9412C38.5515 24.9412 37.3333 23.6771 37.3333 22.1176C37.3333 20.5582 38.5515 19.2941 40.0544 19.2941C41.5573 19.2941 42.7755 20.5582 42.7755 22.1176ZM15.2245 22.4706C15.2245 20.9112 16.4427 19.6471 17.9456 19.6471C19.4485 19.6471 20.6667 20.9112 20.6667 22.4706V33.8074C20.2654 32.6506 19.1985 31.8235 17.9456 31.8235C16.6927 31.8235 15.6257 32.6506 15.2245 33.8074V22.4706ZM17.9456 37.8235C19.1985 37.8235 20.2654 36.9965 20.6667 35.8397V49.2941C20.6667 49.684 20.3621 50 19.9864 50H15.9048C15.5291 50 15.2245 49.684 15.2245 49.2941V35.8397C15.6257 36.9965 16.6927 37.8235 17.9456 37.8235Z" fill="#4A7DFF" />
    </svg>

);
