import React, { FC } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CourseContentEditorView } from 'src/modules/courseContentEditor/ui';
import { CourseDescriptionView } from 'src/modules/courseCreation/ui';
import { Header } from 'src/modules/header/ui';
import { CustomBackdrop } from "../UIKit/CustomBackdrop";
import { QuizView } from 'src/modules/quiz/ui';
import { TermsOfUseView } from 'src/modules/termsOfUse/ui';
import { HelpCenterView } from 'src/modules/helpCenter/ui';
import { PrivatePolicyView } from 'src/modules/privatePolicy/ui';
import { RegistrationView } from 'src/modules/registration/ui';
import { AuthorizationView } from 'src/modules/authorization/ui';
import { TutorialView } from 'src/modules/tutorial/ui';
import { CoursesView } from 'src/modules/courses/ui';
import { MetadialogBotView } from 'src/modules/metadialogBot/ui';
import { BotInfoView } from 'src/modules/botInfo/ui';
import { MetaminderInfoView } from 'src/modules/metaminderInfo/ui';
import { MetadialogBotChatView } from 'src/modules/metadialogBotChat/ui';
import { SiteStoppedView } from 'src/modules/siteStopped';

export const AppRoutes: FC = () => {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={
                    <React.Suspense fallback={<CustomBackdrop />}>
                        <SiteStoppedView />
                    </React.Suspense>
                } />
                {/* 
                 <Route path="/" element={
                    <React.Suspense fallback={<CustomBackdrop />}>
                        <CoursesView />
                    </React.Suspense>
                } />
                <Route path="/course-creation" element={
                    <React.Suspense fallback={<CustomBackdrop />}>
                        <CourseDescriptionView />
                    </React.Suspense>
                } />
                <Route path="/course-content" element={
                    <React.Suspense fallback={<CustomBackdrop />}>
                        <CourseContentEditorView />
                    </React.Suspense>
                } />
                <Route path="/quiz" element={
                    <React.Suspense fallback={<CustomBackdrop />}>
                        <QuizView />
                    </React.Suspense>
                } />
                <Route path="/privacy-policy" element={
                    <React.Suspense fallback={<CustomBackdrop />}>
                        <PrivatePolicyView />
                    </React.Suspense>
                } />
                <Route path="/terms-of-use" element={
                    <React.Suspense fallback={<CustomBackdrop />}>
                        <TermsOfUseView />
                    </React.Suspense>
                } />
                <Route path="/help-center" element={
                    <React.Suspense fallback={<CustomBackdrop />}>
                        <HelpCenterView />
                    </React.Suspense>
                } />
                <Route path="/sign-up" element={
                    <React.Suspense fallback={<CustomBackdrop />}>
                        <RegistrationView />
                    </React.Suspense>
                } />
                <Route path="/sign-in" element={
                    <React.Suspense fallback={<CustomBackdrop />}>
                        <AuthorizationView />
                    </React.Suspense>
                } />
                <Route path="/tutorial" element={
                    <React.Suspense fallback={<CustomBackdrop />}>
                        <TutorialView />
                    </React.Suspense>
                } />
                <Route path="/bots" element={
                    <React.Suspense fallback={<CustomBackdrop />}>
                        <MetadialogBotView />
                    </React.Suspense>
                } />
                <Route path="/bot-info" element={
                    <React.Suspense fallback={<CustomBackdrop />}>
                        <BotInfoView />
                    </React.Suspense>
                } />
                <Route path="/metaminder-info" element={
                    <React.Suspense fallback={<CustomBackdrop />}>
                        <MetaminderInfoView />
                    </React.Suspense>
                } />
                <Route path="/metadialog-bot" element={
                    <React.Suspense fallback={<CustomBackdrop />}>
                        <MetadialogBotChatView />
                    </React.Suspense>
                } /> */}
            </Routes>
        </BrowserRouter>
    );
};
