import React, { FC } from "react";

export const MMIcon: FC = () => (
    <svg width="24" height="24" viewBox="0 0 20 16" fill="none" >
        <g clipPath="url(#clip0_991_2532)">
            <path d="M5.28696 0.000383436H5.33333L5.65797 0H13.542L13.8667 0.000383436H13.913C14.2893 0.000383436 14.6717 0.0474501 15.0497 0.139762C16.3825 0.459164 17.5259 1.30665 18.273 2.46358C18.8541 3.36349 19.1956 4.45053 19.2 5.62174V15.6078C19.2 15.6619 19.1863 15.7126 19.1622 15.7563C19.1138 15.8435 19.0242 15.9022 18.9217 15.9022H17.3449C17.1912 15.9022 17.0667 15.7704 17.0667 15.6078V5.64417C17.0667 3.77387 15.634 2.25767 13.8667 2.25767C12.0993 2.25767 10.6667 3.77387 10.6667 5.64417L10.6666 10.2581C10.6666 10.8815 10.1891 11.3869 9.60009 11.3869C9.01096 11.3869 8.53343 10.8815 8.53343 10.2581L8.53333 5.64417C8.53333 3.77387 7.10063 2.25767 5.33333 2.25767C3.56603 2.25767 2.13333 3.77387 2.13333 5.64417V15.6078C2.13333 15.7704 2.00878 15.9022 1.85507 15.9022H0.278261C0.124547 15.9022 0 15.7704 0 15.6078V5.62174C0.00434783 4.45053 0.345834 3.36349 0.926993 2.46358C1.67409 1.30665 2.81748 0.459164 4.15027 0.139762C4.52835 0.0474501 4.91078 0.000383436 5.28696 0.000383436Z" fill="white" />
            <path d="M4.26794 5.67427L4.26857 5.69354H4.26758V15.6077C4.26758 15.6327 4.27056 15.657 4.27609 15.6802C4.2866 15.7242 4.30653 15.7642 4.33334 15.7978C4.38434 15.8617 4.46061 15.9021 4.54584 15.9021H14.656C14.7064 15.9021 14.7536 15.888 14.7942 15.8634C14.8225 15.8463 14.8476 15.824 14.8685 15.7978C14.8799 15.7836 14.89 15.7682 14.8987 15.7519C14.9057 15.7388 14.9117 15.7252 14.9167 15.711C14.928 15.6788 14.9342 15.6441 14.9342 15.6077V5.69354H14.9332L14.9339 5.67427L14.9342 5.64446C14.9342 5.02099 14.4567 4.51562 13.8676 4.51562C13.2784 4.51562 12.8009 5.02099 12.8009 5.64446C12.8009 5.66095 12.8013 5.67724 12.8019 5.69354H12.8009V10.258C12.8009 11.9624 11.5681 13.6181 9.60091 13.6438C7.6337 13.6181 6.40091 11.9624 6.40091 10.258V5.69354H6.39992C6.40055 5.67724 6.40091 5.66095 6.40091 5.64446C6.40091 5.02099 5.92338 4.51562 5.33424 4.51562C4.74511 4.51562 4.26758 5.02099 4.26758 5.64446L4.26794 5.67427Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_991_2532">
                <rect width="19.2" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);



