import { IColors } from "src/UIProvider/theme/IColors";
import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const getStyle = (colors: IColors) => {
    const styles = {
        titleWrapper: {
            marginLeft: '15px',
            marginRight: '15px',
        },
        title: {
            color: colors.title,
        },
        button: {
            display: 'inline-flex',
            cursor: 'pointer',
            padding: '0 15px',
        },
        logotype: {
            display: 'block',
            maxWidth: '165px',
            width: '100%'
        },
        helpCenter: {
            fontSize: '14px',
            color: colors.buttonText,
        },
        menuContainer: {
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
        },
        helpCenterButton: {
            marginRight: '20px',
            padding: '5px 15px',
            borderRadius: '5px',
            '&:hover': {
                backgroundColor: '#182747'
            },
            cursor: 'pointer',
        }
    };
    return styles;
}

export const Wrapper = styled(Box) <any>`
  width: 100%;
  position: fixed;
  z-index: 1000;
  display: flex;
  height: 80px;
  background-color: ${({ colors }) => colors.header};   
  padding: 0 60px;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 640px) {
    padding: 0 15px;
  }
`;
