import { Box, Container } from "@mui/material";
import React, { FC } from "react";

interface IProps {
    children: React.ReactNode | React.ReactNode[];
    containerStyle?: React.CSSProperties;
}

export const PageContainer: FC<IProps> = ({ children, containerStyle }) => (
    <Container sx={styles.container} >
        <Box sx={styles.wrapper} style={containerStyle}>
            {children}
        </Box>
    </Container>
)

const styles = {
    container: {
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingTop: '16px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '120px',
        '@media (max-width: 640px)': {
            paddingLeft: '14px',
            paddingRight: '14px',
        }
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '920px',
    }
} 