import React, { FC, useEffect } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import './App.css';
import { AppRoutes } from './navigation/AppRoutes';
import { CustomBackdrop } from './UIKit/CustomBackdrop';
import { UIProvider } from './UIProvider';
import { useSaveUtmParams } from "./hooks/useSaveUtmParams";
import ReactGA from "react-ga4";
import { observer } from 'mobx-react';
import { useTidioChat } from './hooks/useTidioChat';
import { useGetCurrentUser } from './hooks/useGetCurrentUser';

export const App: FC = observer(() => {
    // useGetCurrentUser();
    // useSaveUtmParams();
    // useTidioChat();

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID as string);
    }, []);

    return (
        <UIProvider>
            <div>
                <ReactNotifications />
                <AppRoutes />
                <CustomBackdrop />
            </div>
        </UIProvider>
    );
});
