import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useUiContext } from 'src/UIProvider';
import { PageContainer } from 'src/UIKit/PageContainer';
import { Box, Typography } from '@mui/material';
import { getStyle } from './styles';

export const SiteStoppedView: FC = observer(() => {
    const { t, colors } = useUiContext();
    const styles = useMemo(() => getStyle(colors), [colors]);

    return (
        <PageContainer >
            <Box sx={styles.container} >
                <Typography sx={styles.capture}>The functionality is in development now. We will keep you updated.</Typography>
            </Box><Box />
            
        </PageContainer>
    );
})
