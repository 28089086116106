import { IColors } from "src/UIProvider/theme/IColors";

export const getStyle = (colors: IColors) => {
    const styles = {
        container: {
            display: 'flex',
            flex: 1,
            minHeight: '100vh',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        capture: {
            fontSize: '18px',
            color: colors.text,
            marginTop: '40px',
        }
    };
    return styles;
}
