import { useEffect, useState } from 'react';


interface IWindowSize {
  width: number,
  height: number
  isMobile: boolean;
}

const getWindowSize = (): IWindowSize => {
  const { innerWidth: width, innerHeight: height } = window;
  const isMobile = width < 600;
  return { width, height, isMobile };
};

export const useWindowSize = (): IWindowSize => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowSize(),);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowDimensions.width < 600;

  return { ...windowDimensions, isMobile };
};
