import { storage } from "src/libraries/storage";
import { MobXRepository } from "src/repository/MobXRepository";
import { ICourse } from "./ICourse";
import { ICourseLesson } from "./ICourseLesson";
import { Locale } from "src/UIProvider/localization/ILocalization";
import { IPhoto } from "../photos/IPhoto";

export interface IChosenCourseModel {
    id?: number;
    name: string;
    description: string;
    audience: string;
    initLessonAmount: number;
    course: ICourse | null;
    parentMessageId: string | null;
    amountGeneratedLessons: number;
    courseLanguage: Locale;
}

const INITIAL_COURSE: ICourse = {
    name: '',
    description: '',
    audience: '',
    initLessonAmount: 5,
    image: null,
    lessons: [],
};

class ChosenCourseModel implements IChosenCourseModel {
    private courseLanguageRepository = new MobXRepository<Locale>('en');
    private amountGeneratedLessonsRepository = new MobXRepository<number>(0);
    private courseRepository = new MobXRepository<ICourse>(INITIAL_COURSE);
    private parentMessageIdRepository = new MobXRepository<string | null>(null);

    constructor() {
        this.onLoad();
    }
    private onLoad = () => {
        const data = storage.get('course');
        data && this.courseRepository.save(data);
        const parentMessageId = storage.get('parentMessageId');
        parentMessageId && this.parentMessageIdRepository.save(parentMessageId);
        const courseLanguage = storage.get('courseLanguage');
        courseLanguage && this.courseLanguageRepository.save(courseLanguage);
    }

    private onPersist = (data: ICourse) => {
        storage.set('course', data);
    }

    private onPersistParentMessageId = (data: string | null) => {
        if (data) {
            storage.set('parentMessageId', data);
        } else {
            storage.remove('parentMessageId');
        }
    }

    private onPersistCourseLanguage = (data: string) => {
        storage.set('courseLanguage', data);
    }

    get courseLanguage() {
        return this.courseLanguageRepository.data || 'en';
    }

    set courseLanguage(data: Locale) {
        this.courseLanguageRepository.save(data);
        this.onPersistCourseLanguage(data);
    }

    get amountGeneratedLessons() {
        return this.amountGeneratedLessonsRepository.data ?? 0;
    }

    set amountGeneratedLessons(data: number) {
        this.amountGeneratedLessonsRepository.save(data);
    }

    get parentMessageId() {
        return this.parentMessageIdRepository.data;
    }

    set parentMessageId(data: string | null) {
        this.parentMessageIdRepository.save(data);
        this.onPersistParentMessageId(data);
    }

    get course() {
        return this.courseRepository.data as ICourse;
    }

    set course(data: ICourse | null) {
        this.courseRepository.save(data);
        this.onPersist(data as ICourse);
    }

    get lessons() {
        return this.courseRepository.data?.lessons || [];
    }

    set lessons(data: ICourseLesson[]) {
        this.course = { ...this.course, lessons: data } as ICourse;
    }

    onUpdateLesson = (lesson: ICourseLesson) => {
        const lessons = this.lessons.map((item) => {
            if (item.key === lesson.key) {
                return lesson;
            }
            return item;
        });
        this.lessons = lessons;
    }

    get image() {
        return this.courseRepository.data?.image || null;
    }

    set image(data: IPhoto | null) {
        this.course = { ...this.course, image: data } as ICourse;
    }

    get name() {
        return this.courseRepository.data?.name || '';
    }

    set name(data: string) {
        this.course = { ...this.course, name: data } as ICourse;
    }

    get description() {
        return this.courseRepository.data?.description || '';
    }

    set description(data: string) {
        this.course = { ...this.course, description: data } as ICourse;
    }

    get audience() {
        return this.courseRepository.data?.audience || '';
    }

    set audience(data: string) {
        this.course = { ...this.course, audience: data } as ICourse;
    }

    get initLessonAmount() {
        return this.courseRepository.data?.initLessonAmount || 10;
    }

    set initLessonAmount(data: number) {
        this.course = { ...this.course, initLessonAmount: data } as ICourse;
    }

    get id() {
        return this.courseRepository.data?.id;
    }

    set id(data: number | undefined) {
        this.course = { ...this.course, id: data } as ICourse;
    }

    clear = () => {
        this.course = INITIAL_COURSE;
        this.parentMessageId = null;
        this.courseLanguage = 'en';
    }

}

export const chosenCourseModel = new ChosenCourseModel();
