// @ts-ignore
import browserSignature from 'browser-signature';
import { MobXRepository } from 'src/repository/MobXRepository';
import { IUser } from './IUser';
import { storage } from 'src/libraries/storage';

interface IUserModel {
    user: IUser | null;
    token: string | null;
}

class UserModel implements IUserModel {
    private _signature: string;
    private userRepository = new MobXRepository<IUser | null>(null);
    private tokenRepository = new MobXRepository<string | null>(null);

    constructor() {
        this._signature = browserSignature();
        this.onLoad();
    }

    private onLoad = () => {
        const data = storage.get('user');
        data && this.userRepository.save(data);
        const token = storage.get('token');
        token && this.tokenRepository.save(token);
    }

    private onPersist = (data: IUser | null) => {
        if (data) {
            storage.set('user', data);
        } else {
            storage.remove('user');
        }
    }

    private onPersistToken = (data: string | null) => {
        if (data) {
            storage.set('token', data);
        } else {
            storage.remove('token');
        }
    }

    get signature() {
        return this.user?.id ? String(this.user?.id) : this._signature;
    }

    get user() {
        return this.userRepository.data;
    }

    set user(data: IUser | null) {
        this.userRepository.save(data);
        this.onPersist(data);
    }

    get token() {
        return this.tokenRepository.data;
    }

    set token(data: string | null) {
        this.tokenRepository.save(data);
        this.onPersistToken(data);
    }

    clear() {
        this.user = null;
        this.token = null;
    }

}

export const userModel = new UserModel();