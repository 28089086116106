import React, { FC, memo, useMemo } from 'react';
import { useHeader } from '../presenters/useHeader';
import { Box, Typography } from '@mui/material';
import { AppLogoIcon } from 'src/UIKit/icons/AppLogoIcon';
import { ArrowLeftIcon } from 'src/UIKit/icons/ArrowLeft';
import { BurgerIcon } from 'src/UIKit/icons/BurgerIcon';
import { ChatBotIcon } from 'src/UIKit/icons/ChatBotIcon';
import { HelpCenterIcon } from 'src/UIKit/icons/HelpCenterIcon';
import { PrivatePolicyIcon } from 'src/UIKit/icons/PrivatePolicyIcon';
import { TermOfUseIcon } from 'src/UIKit/icons/TermOfUseIcon';
import { useUiContext } from 'src/UIProvider';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { HeaderMenu } from './components/headerMenu';
import { getStyle, Wrapper } from './styles';
import { MMIcon } from 'src/UIKit/icons/MMIcon';

const EXCLUDED_PATHS = ['/sign-up', '/sign-in', '/tutorial'];

export const Header: FC = memo(() => {
    const { colors, t, locale, isRtL } = useUiContext();
    const styles = useMemo(() => getStyle(colors), [colors]);
    const { isMobile } = useWindowSize();
    const {
        opened, containerRef, onLogout, onGoToLogin, showBackButton, location, onGoBack, onGoToCourses,
        onClickMenu, onGoToTermsOfUse, onGoToStart, onGoToHelpCenter, onGoToPrivacyPolicy, onGoToMMInfo
    } = useHeader();

    const icon = useMemo(() => {
        const icons = {
            '/privacy-policy': <PrivatePolicyIcon />,
            '/terms-of-use': <TermOfUseIcon />,
            '/help-center': <HelpCenterIcon />,
            '/bots': <ChatBotIcon />,
            '/bot-info': <ChatBotIcon />,
            '/metaminder-info': <MMIcon />,
        };
        return icons[location.pathname] || null;
    }, [location]);

    const text = useMemo(() => {
        const texts = {
            '/privacy-policy': t('privacyPolicy'),
            '/terms-of-use': t('termsOfUse'),
            '/help-center': t('helpCenter'),
            '/quiz': t('quiz'),
            '/bots': t('chatBot'),
            '/bot-info': t('chatBotInfo'),
            '/metaminder-info': t('metaminderInfo'),
        };
        return texts[location.pathname] || null;
    }, [t, location, locale]);

    if (EXCLUDED_PATHS.includes(location.pathname)) return null;

    return (
        <Box ref={containerRef}>
            <Wrapper colors={colors}>
                {showBackButton ?
                    <Box sx={styles.menuContainer}>
                        <Box sx={styles.button} onClick={onGoBack} >
                            <ArrowLeftIcon isRtL={isRtL} />
                        </Box>
                        {icon}
                        <Box sx={styles.titleWrapper} >
                            <Typography sx={styles.title}>{text?.toUpperCase()}</Typography>
                        </Box>
                    </Box>
                    : <Box sx={styles.button} onClick={onGoToStart}>
                        <AppLogoIcon />
                    </Box>}
                {/* <Box sx={styles.menuContainer}>
                    {isMobile ? null : <Box sx={styles.helpCenterButton} onClick={onGoToHelpCenter}>
                        <Typography sx={styles.helpCenter}>{t('helpCenter')}</Typography>
                    </Box>}
                    <Box sx={styles.button} onClick={onClickMenu}>
                        <BurgerIcon />
                    </Box>
                </Box> */}
            </Wrapper>
            {/* {opened ? <HeaderMenu
                onGoToMMInfo={onGoToMMInfo}
                onGoToCourses={onGoToCourses}
                onLogout={onLogout}
                onGoToLogin={onGoToLogin}
                onGoToTermsOfUse={onGoToTermsOfUse}
                onGoToHelpCenter={onGoToHelpCenter}
                onGoToPrivacyPolicy={onGoToPrivacyPolicy}
            /> : null}
            <Box height={'80px'} /> */}
        </Box>
    );
});
